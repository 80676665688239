import React, { useEffect, useState } from "react";
import { SampleImage } from "../../types";
import ImageModal from "../ImageModal/ImageModal";
import "./index.scss";

interface Props {
  imageData: SampleImage
}

export default function Image({ imageData }: Props) {
  const [fullscreen, setFullscreen] = useState(false)
  const { name, width } = imageData;

  const toggle = () => setFullscreen(fullscreen => !fullscreen)

  useEffect(() => {
    if (fullscreen) document.body.style.overflowY = "hidden"
    else document.body.style.overflowY = "scroll"
  }, [fullscreen])

  return (
    <>
      <div className="image-preview-wrapper" onClick={toggle}>
        <img className="preview-image" src={require(`../../assets/images/samples/${name}.jpg`)} loading="lazy" alt="img" style={{ width: `${width}vw` }}  />
      </div>
      {fullscreen && <ImageModal imageData={imageData} toggle={toggle} />}
    </>
  )
}
