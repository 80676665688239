import React from "react";
import { COUNTRIES_TRAVELLED, ElementsIds, Links } from "../../constants";
import BookImages from "./components/BookImages/BookImages";
import "./index.scss";

export default function Content() {
  return (
    <div className="content-wrapper">
      <div id={ElementsIds.TheSaga} className="text-box">
        <h2>—&nbsp;How Everything Started&nbsp;—</h2>
        <p>
          Travel and photography have always sparked a passion in me. However,
          during my first trip to India in 2016, I found a new direction.
          Unexpected encounters and profound experiences opened my eyes,
          revealing a life and dream I truly wanted to pursue.
        </p>
        <p>
          Waking up to the peaceful sounds of Indian mantras, with the
          snow-capped Himalayas as my backdrop, and riding the Royal Enfield
          into the sunset — these are emotions that words can barely capture.
        </p>
      </div>

      <div className="parallax-image img_1">
        <p className="everything-is-possible">
          Sab Kuch Milega &#40;in Hindi: सब कुछ मिलेगा &#41; means
          <br />
          <span className="italic-text bold-text">everything is possible</span>
        </p>
      </div>

      <div className="text-box">
        <p>
          The transformation was gradual, but 2019 marked my commitment. I
          decided to leave behind the conventional path, stepping out of my
          comfort zone and letting go of material possessions — my car, my
          drums set, and more. With a one-way ticket to Nepal, I embarked on a
          journey, and the rest is history...
        </p>
      </div>


      <div className="parallax-image img_2" />

      <div id={ElementsIds.TheBook}>
        <div className="text-box">
          <h2>—&nbsp;The Book&nbsp;—</h2>
          <p>
            These days I'm working on a captivating book that will feature stunning visuals from around the world,
            highlighting the planet's breathtaking beauty and diversity. Each image is paired with extraordinary stories—many of which will surprise and engage readers,
            sparking curiosity about places and people they may have never known. Additionally, the book includes
            powerful portraits from my project&nbsp;
            <a className="italic-text" href={Links.PEOPLE_OF_THE_PLANET} target="_blank" rel="noreferrer">"<u>People of the Planet</u>".</a>
          </p>
        </div>
        <BookImages />
      </div>

      <div id={ElementsIds.TheAuthor} className="text-box">
        <h2>—&nbsp;The Author&nbsp;—</h2>
        <p>
          Roie Natan, born in 1993, has embraced the nomadic lifestyle for over half a decade.
          His passion for travel and photography has been a constant throughout his journey.
          Having explored more than {COUNTRIES_TRAVELLED} countries and 7 continents,
          Roie is driven to showcase the breathtaking beauty and rich diversity of our world.
          Inspired by a deep love for exploration, Roie aims to visit at least 100 countries,
          sharing captivating stories from his adventures.
        </p>
        <img src={require("../../assets/images/author.png")} className="author-image" alt="roie natan" />
        <p>
          <span className="italic-text">"In an era increasingly overshadowed by artificial intelligence,
            I am steadfast in my belief that sharing genuine and heartfelt content paves the way for a more harmonious world."
          </span>
        </p>
      </div>

      <div id={ElementsIds.Collaborations} className="text-box">
        <h2>—&nbsp;Collaborations&nbsp;—</h2>
        <p>
          If you appreciate the work presented here and interested in exploring further or discussing
          a potential partnership, please feel free to reach out!
          I'm also open to present my work in galleries and exhibitions or participate in podcasts.
        </p>
      </div>
    </div>
  )
}
