import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { SampleImage } from "../../types";
import { useLayout } from "../../contexts/LayoutContext";
import "./index.scss";

interface Props {
 imageData: SampleImage
 toggle: () => void
}

export default function ImageModal({ imageData, toggle }: Props) {
 const { name, title, location, text } = imageData;
 const { isMobile } = useLayout();

 const imageElement = useMemo(() => (
  <figure>
   <img className="fullscreen-image" src={require(`../../assets/images/samples/${name}.jpg`)} alt="img" />
   <figcaption className="image-caption">
    <span className="image-title">
     {title}
     {!isMobile && "."}
     &nbsp;
    </span>
    <span className="image-location">{location}</span>
   </figcaption>
  </figure>
 ), [name, location, title, isMobile])

 useEffect(() => {
  const handleEsc = (event: KeyboardEvent) => {
   if (event.key === 'Escape') {
    toggle();
   }
  };

  window.addEventListener('keydown', handleEsc);

  return () => {
   window.removeEventListener('keydown', handleEsc);
  };
 }, [toggle]);

 return createPortal(
  <div className="image-fullscreen-wrapper">
   {!isMobile && <div className="close-btn" onClick={toggle}>&times;</div>}
   <div className="image-and-text-wrapper">
    {imageElement}
    <div className="image-text">{text}</div>
   </div>
   {isMobile && <button className="back-btn" onClick={toggle}>&#8592; BACK</button>}
  </div>,
  document.body
 )
}
