import React, { useEffect, useRef, useState } from "react";
import Image from "../../../Image/Image";
import { SAMPLE_IMAGES_BOTTOM_ROW, SAMPLE_IMAGES_TOP_ROW } from "../../../../data/sampleImages";
import "./index.scss";

const INTERSECTION_OBSERVER_THRESHOLD = 0.1;

export default function BookImages() {
 const [scrollY, setScrollY] = useState(0);
 const [isInView, setIsInView] = useState(false);
 const containerRef = useRef(null);
 const topRowImages = SAMPLE_IMAGES_TOP_ROW.map((image, index) => <Image key={index} imageData={image} />)
 const bottomRowImages = SAMPLE_IMAGES_BOTTOM_ROW.map((image, index) => <Image key={index} imageData={image} />)

 useEffect(() => {
  const handleScroll = () => {
   if (isInView) {
    setScrollY(window.scrollY);
   }
  };

  window.addEventListener("scroll", handleScroll);
  return () => window.removeEventListener("scroll", handleScroll);
 }, [isInView]);

 useEffect(() => {
  const observer = new IntersectionObserver(
   ([entry]) => {
    setIsInView(entry.isIntersecting);
   },
   {
    threshold: INTERSECTION_OBSERVER_THRESHOLD,
   }
  );

  const currentContainer = containerRef.current;

  if (currentContainer) {
   observer.observe(currentContainer);
  }

  return () => {
   if (currentContainer) {
    observer.unobserve(currentContainer);
   }
  };
 }, []);

 return (
  <div className="sample-images-wrapper" ref={containerRef}>
   <div className="image-row top" style={{ transform: `translateX(${scrollY * 0.1 - 250}px)` }}>
    {topRowImages}
   </div>

   <div className="image-row bottom" style={{ transform: `translateX(-${scrollY * 0.1 - 250}px)` }}>
    {bottomRowImages}
   </div>
  </div>
 )
}

