import { SampleImage } from "../types";

export const SAMPLE_IMAGES_TOP_ROW: SampleImage[] = [
  {
    name: "img_3",
    title: "The Illustrious Tale of Samarkand Obi Non",
    location: "Samarkand, Uzbekistan",
    text: "In Uzbekistan, myriad breads grace our tables — from the delicate loaves of Tashkent to the flavorsome rounds from Bukhara and Khiva. Yet, standing unparalleled in its reputation and grandeur is the Obi Non of Samarkand, hailed as the crowning jewel of Uzbekistani Non.\n\nAncient tales whisper of a custom where, before embarking on a voyage, a man would take a tender bite from his cherished Uzbek Non. The remaining loaf, then, would find its place adorning the wall, patiently awaiting the traveler's homecoming.\n\nIn the annals of the Özbeg Khan dynasty, there unfolds another tale: the Khan, captivated by the unique essence of Samarkand bread, commissioned his courtiers to summon the finest bakers and their tools from the city of Samarkand to recreate this marvel in Bukhara. Yet, the replicated bread lacked the ethereal touch of the original. Perplexed, when the Khan sought an answer from the artisans, they responded with poetic candor, ”No matter what we carry with us, the ethereal breath of Samarkand's air eludes our grasp”.",
    width: 25
  },
  {
    name: "img_2",
    title: "The Apatani Tribe",
    location: "Ziro Valley, Arunachal Pradesh, India",
    text: "In the secluded region of Ziro Valley, one can find the exclusive presence of the Apatani tribe. According to the most renowned legend, the enchanting beauty of Apatani maidens made them targets for abductions by neighboring tribesmen. As a protective measure, and to mark their identity, the tribe resolved to embellish their faces with intricate tattoos and to adorn them with distinctive nose plugs — a process ostensibly designed for 'uglification'. Ironically, my visit to this unique region coincided with International Women's Day.\n\nIn contemporary times, the burgeoning generation of the Apatani tribe has chosen to abandon this ancestral rite. The women of this tribe are the very last on earth carrying this ancient tradition.",
    width: 30
  },
  {
    name: "img_4",
    title: "The White Continent",
    location: "Antarctica",
    text: "Setting foot on the most remote continent of our planet, Antarctica, is an indescribable feeling. As the only place on Earth without permanent human habitation, it remains a pristine and untouched wilderness. The first recorded landing on this frozen frontier took place on February 7, 1821.\n\nWhat makes Antarctica unique is not only its vast, icy expanse but also the treaty that protects it. Signed in 1959, the Antarctic Treaty designates the continent as a place of peace and scientific research, banning military activity, mineral mining, and nuclear testing while encouraging international cooperation. This treaty is set to expire in 2048, and I can only hope that when the time comes, the world will choose to extend its protection indefinitely, preserving this last truly virgin place on Earth for future generations.",
    width: 25
  },
  {
    name: "img_1",
    title: "Wild and Free",
    location: "The steppes of Bayan-Ölgii Province, Mongolia",
    text: "Approximately 10,000 years ago the emergence of agricultural societies marked a pivotal epoch when humanity commenced the domestication of flora and fauna. The equines we behold today epitomize a testament to this profound domestication, serving humanity's manifold needs. Yet, in a secluded enclave, distanced from the throbbing pulse of modernization and industry, there exists a singular breed of horse that retains its untamed spirit even now. Though on the brink of extinction, this majestic creature graces the vast expanses of the Mongolian steppes — the Przewalski's horse.",
    width: 20
  },
]

export const SAMPLE_IMAGES_BOTTOM_ROW: SampleImage[] = [
  {
    name: "img_7",
    title: "Brides for Cattle",
    location: "Madagascar",
    text: "In southern Madagascar, a traditional practice persists where girls, sometimes promised before birth, are exchanged for Zebu cattle in arranged marriages, often to older men. This custom is driven by poverty and cultural norms. Some girls, as young as 13, face the pressure of becoming wives, and those who resist are disowned or worse. Organizations like UNICEF are working to educate communities on gender equality and positive masculinity, aiming to end this illegal yet culturally accepted practice.",
    width: 20
  },
  {
    name: "img_8",
    title: "The Pearl of Central Asia",
    location: "The Pamir, Tajikistan",
    text: "Once part of the legendary Silk Road, Central Asia’s Pamir Mountains are a major reason why I call Tajikistan the “pearl of Central Asia.” The Silk Road, a vast network of trade routes, connected East and West for centuries, enabling the exchange of goods, ideas, and cultures across continents. These mountains were a formidable challenge for convoys, but also a crucial passage that linked traders from China to the Mediterranean.\n\nBesides the extraordinary vistas you’ll encounter and the welcoming locals you’ll meet along the way, you’ll also see parts of Afghanistan from the Tajik side of the Pamirs. The Panj River, often just 30 meters wide, serves as a natural boundary between the two nations. You can literally wave across the water to people in Afghanistan.\n\nIn this photograph, we glimpse a convoy of Kuchi nomads, Afghan pastoralists whose lifestyle revolves around seasonal migration. For centuries, the Kuchis have traversed these rugged landscapes with their camels and donkeys, moving between pastures in search of better grazing lands for their livestock, continuing a way of life that remains remarkably unchanged by time.",
    width: 25
  },
  {
    name: "img_5",
    title: "The Kings Colony",
    location: "Fortuna Bay, South Georgia Islands",
    text: "Sir David Attenborough, a famous producer of nature and wildlife films praised the South Georgia Islands: 'global rarity'. Probably one of the most photogenic places in our world. 'Paradise for photographers' — that's what I felt when I set foot there for the first time.\n\nFortuna Bay is surrounded by towering mountains with snow-capped peaks. You'll find there one of the most thriving colonies of king penguins. However this bay has a difficult history of whaling by humans for profit from the extraction of whale oil and meat. 'Fortuna Bay' was named after the first whaling ship that operated in the area in the early 20th century.\n\nFortunately today whaling is illegal in most countries of the world.",
    width: 30
  },
  {
    name: "img_11",
    text: "[In Progress]",
    width: 25
  },
]