export const Links = {
  PEOPLE_OF_THE_PLANET: "https://www.instagram.com/people.of.the.planet",
  FACEBOOK_PROFILE: "https://www.facebook.com/roie1993",
  MOSAIC_OF_EARTH: "https://www.instagram.com/mosaic.of.earth",
}

export enum ElementsIds {
  TheSaga = "saga",
  TheAuthor = "author",
  TheBook = "book",
  Collaborations = "collaborations",
  Contact = "contact",
}

export const MOBILE_BREAKPOINT = 768;

export const PRIVATE_EMAIL = "roie.natan@gmail.com";

export const COUNTRIES_TRAVELLED = "70";
